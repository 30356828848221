import React, { useRef } from "react";

// STYLE
import "./taskSlider.scss";

import { Row, Col } from "reactstrap";

// COMPONENTS
import Heading from "../../atoms/Heading/Heading";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";
import SlickSlider from "../../atoms/SlickSlider/SlickSlider";
import TaskCard from "../../molecules/TaskCard/TaskCard";

const NextButton = (props) => {
    const { className, style, onClick } = props;
    return (
        <Button
            className="slick--next btn--circle"
            onClick={onClick}
            aria-label="Następne wyzwanie"
        >
            <Icon className="icon-arrow-back" />
        </Button>
    );
};

const PrevButton = (props) => {
    const { className, style, onClick } = props;
    return (
        <Button
            className="slick--prev btn--circle"
            onClick={onClick}
            aria-label="Poprzednie wyzwanie"
        >
            <Icon className="icon-arrow-back" />
        </Button>
    );
};

const TaskSlider = ({ data, title, desc, icon }) => {
    const settings = {
        centerMode: true,
        infinite: false,
    };

    const sliderRef = useRef();

    return (
        <div className="task-slider">
            <Row>
                <Col className="task-slider--title-icon">
                    <div className="icon--container">
                        <Icon className={icon}></Icon>
                    </div>
                </Col>
                <Col className="task-slider--desc">
                    <Heading as="h3">{title}</Heading>
                    <Paragraph>{desc}</Paragraph>
                </Col>
            </Row>
            <div className="task-slider--top">
                <div className="btn--group">
                    <PrevButton onClick={() => sliderRef.current.slickPrev()} />
                    <NextButton onClick={() => sliderRef.current.slickNext()} />
                </div>
            </div>
            <div className="slider-with-peek">
                <SlickSlider
                    innerRef={sliderRef}
                    className="task-slider--items"
                    settings={settings}
                >
                    {data
                        ?.filter((item) => item.type !== 4)
                        .map((el, i) =>
                            i < 2 ? <TaskCard data={el} key={i} /> : ""
                        )}
                </SlickSlider>
            </div>
        </div>
    );
};

export default TaskSlider;
