import React, { createRef, useState } from "react";
import { useDispatch /*, useSelector */ } from "react-redux";

// STYLE
import "./routesSlider.scss";

// COMPONENTS

// ACTIONS
import { setCurrentRoute } from "../../../state/actions";

// COMPONENTS
import { Container, Row, Col } from "reactstrap";

import SlickSlider from "../../atoms/SlickSlider/SlickSlider";
import RouteCard from "../../molecules/RouteCard/RouteCard";
import useRoutes from "../../../hooks/redux/useRoutes";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";

const NextButton = (props) => {
    const { className, style, onClick } = props;
    return (
        <Button
            className="slick--next btn--circle"
            onClick={onClick}
            aria-label="Następna trasa"
        >
            <Icon className="icon-arrow-back" />
        </Button>
    );
};

const PrevButton = (props) => {
    const { className, style, onClick } = props;
    return (
        <Button
            className="slick--prev btn--circle"
            onClick={onClick}
            aria-label="Poprzednia trasa"
        >
            <Icon className="icon-arrow-back" />
        </Button>
    );
};

const RouteSlider = ({
    sliderRef,
    centerMode = true,
    slidesToShow = 3,
    variableWidth = false,
    withHeading = false,
    routeId,
    isRouteView,
    position,
    mapRef,
}) => {
    const dispatch = useDispatch();
    // const route = useSelector((state) => state.route);
    const [{ routes }] = useRoutes();
    const [dragging, setDragging] = useState(false);

    const next = () => {
        sliderRef.current.slickNext();
    };

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow,
        slidesToScroll: 1,
        centerMode,
        variableWidth,
        arrows: false,
        afterChange: (current) => {
            setDragging(false);
            dispatch(setCurrentRoute(current));

            // console.log(mapRef);
            // console.log(routes[current]);

            // const locations = routes[current]?.places.map((a) => {
            //     return [a.lon, a.lat];
            // });

            // if (mapRef && mapRef.fitBounds) {
            //     mapRef.fitBounds(locations);
            // }
        },
        beforeChange: () => setDragging(true),
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        mobileFirst: true,
    };

    const routesArr = routeId
        ? routes.filter((route) => route.id !== routeId)
        : routes;

    return (
        <>
            <div
                className={`routes-slider ${position && "routes-slider--" + position
                    }`}
            >
                {position ? (
                    <Container>
                        <Row>
                            <Col>
                                <div className="routes-slider--top">
                                    <Button
                                        className="btn btn-default btn--secondary__sm mb-0"
                                        withoutaction="true"
                                    >
                                        Wybierz trasę
                                    </Button>
                                    <div className="btn--group">
                                        <PrevButton onClick={previous} />
                                        <NextButton onClick={next} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <div className="routes-slider--top">
                        {withHeading && (
                            <h3 className="h3 mb-0">Trasy dla Ciebie</h3>
                        )}
                        <div className="btn--group">
                            <PrevButton onClick={previous} />
                            <NextButton onClick={next} />
                        </div>
                    </div>
                )}
                <div className="routes-slider--container">
                    <SlickSlider innerRef={sliderRef} settings={settings}>
                        {routesArr &&
                            routesArr.map((route, index) => (
                                <div
                                    className="routes-slider--item"
                                    key={index}
                                // onClick={() => {
                                //     setCurrentRoute(index);
                                //     sliderRef.current.slickGoTo(index);
                                // }}
                                >
                                    <RouteCard
                                        className="route-card--sm"
                                        route={route}
                                        dragging={dragging}
                                        isRouteView={isRouteView}
                                    />
                                </div>
                            ))}
                    </SlickSlider>
                </div>
            </div>
        </>
    );
};

export default RouteSlider;
