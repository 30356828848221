import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

// STYLE
import "./index.scss";

// ASSETS
import RunIcon from "../../assets/images/run-min.svg";

// ACTIONS
import { hideBottomNav, hideHeader, logOut } from "../../state/actions";

import { Container, Row, Col } from "reactstrap";

import Layout from "../../layout";
import SEO from "../../components/seo";

// ATOMS
import Heading from "../../components/atoms/Heading/Heading";
import Paragraph from "../../components/atoms/Paragraph/Paragraph";
import PlainText from "../../components/atoms/PlainText/PlainText";
import Button from "../../components/atoms/Button/Button";
import Icon from "../../components/atoms/Icon/Icon";
import Tag from "../../components/atoms/Tag/Tag";

// MOLECULES
import PageHeader from "../../components/molecules/PageHeader/PageHeader";

// ORGANISMS
import TaskSlider from "../../components/organisms/TaskSlider/TaskSlider";
import RoutesSlider from "../../components/organisms/RoutesSlider/RoutesSlider";

// DATA
import useRoutes from "../../hooks/redux/useRoutes";
import TaskMarker from "../../components/atoms/TaskMarker/TaskMarker";
import PlaceMarker from "../../components/atoms/PlaceMarker/PlaceMarker";

import clockSvg from "../../assets/images/clock.svg";
import * as LUtils from "../../utils/leafletUtils";
import {
    useAuth,
    useLocationDeniedModal,
    useRegisterModal,
} from "../../hooks/redux";
import { navigate } from "gatsby";
import Modal from "../../components/molecules/Modal/Modal";
import RouteTutorialModal from "../../components/molecules/RouteTutorialModal/RouteTutorialModal";

// import { MapContainer, Polyline, TileLayer } from "react-leaflet";
const { MapContainer, Polyline, TileLayer } =
    typeof window !== "undefined" ? require("react-leaflet") : {};

const difficultyToClassName = (difficulty) =>
    difficulty === "Łatwy"
        ? "easy"
        : difficulty === "Średni"
            ? "medium"
            : difficulty === "Trudny"
                ? "hard"
                : undefined;

const SingleRoute = ({ params }) => {
    const MAX_DISTANCE_TO_START = 3000;
    const { id } = params;
    if (!id || typeof window === "undefined") return <></>;

    const mapRef = useRef();
    const sliderRef = useRef();
    const [{ routes }, { setRoute }] = useRoutes();
    const route = routes.find((route) => route?.id.toString() === id);
    const [distanceModal, setDistanceModal] = useState(false);
    const [locationDeniedModal, { showLocationDeniedModal }] =
        useLocationDeniedModal();
    const [tutorialModal, setTutorialModal] = useState(false);

    const dispatch = useDispatch();

    let markers,
        paths,
        center = "";

    useEffect(() => {
        dispatch(hideHeader());
        dispatch(hideBottomNav());

        createMapElements();

        const locations = route?.places.map((a) => {
            return [a.lon, a.lat];
        });

        mapRef.current?.fitBounds(locations, {
            padding: [70, 70],
        });

        if (document) {
            document.body.classList.remove("blink");
            document
                .getElementsByTagName("html")[0]
                .classList.add("no-smooth-scroll");

            return () => {
                document
                    .getElementsByTagName("html")[0]
                    .classList.remove("no-smooth-scroll");
            };
        }
    }, [route]);

    // useEffect(() => {
    //     mapRef?.current?.
    // }, [mapRef]);

    const [auth, { updateFavorites, updateStartedRoutes }] = useAuth();
    const [modal, { showRegisterModal, setUrlRegisterModal }] =
        useRegisterModal();

    const isRouteFavorited = (id) =>
        auth?.data?.favorites?.includes(id) ?? false;

    const onFavoriteClick = (id) => async (event) => {
        if (auth.isLoggedIn) {
            if (isRouteFavorited(id)) {
                try {
                    const response = await axios.delete(
                        `${process.env.STRAPI_API_URL}/users/me/favorites/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth.data.jwt}`,
                            },
                        }
                    );

                    setRoute(response.data.route);
                    updateFavorites(response.data.user.favorites);
                } catch (error) {
                    const { status7 } = error.response;

                    if (status === 401 || status === 403 || status === 405) {
                        dispatch(logOut());
                        navigate("/app/logowanie");
                    }
                }
            } else {
                try {
                    const response = await axios.post(
                        `${process.env.STRAPI_API_URL}/users/me/favorites/${id}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${auth.data.jwt}`,
                            },
                        }
                    );

                    setRoute(response.data.route);
                    updateFavorites(response.data.user.favorites);
                } catch (error) {
                    const { status } = error.response;

                    if (status === 401 || status === 403 || status === 405) {
                        dispatch(logOut());
                        navigate("/app/logowanie");
                    }
                }
            }
        } else {
            setUrlRegisterModal("");
            showRegisterModal();
        }
    };

    const onStartRoute = (id, ommitDistanceCheck) => async (event) => {
        if (localStorage.getItem("locationStatus") === "denied") {
            showLocationDeniedModal();
            return;
        }

        if (!ommitDistanceCheck) {
            mapRef.current.locate({ enableHighAccuracy: true, maximumAge: 0 });
            return;
        }

        setDistanceModal(false);

        if (auth.isLoggedIn) {
            try {
                if (
                    auth.data.started_routes.indexOf(id) === -1 &&
                    auth.data.finished_routes.indexOf(id) === -1
                ) {
                    const response = await axios.post(
                        `${process.env.STRAPI_API_URL}/users/me/start-route/${id}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${auth.data.jwt}`,
                            },
                        }
                    );

                    updateStartedRoutes(response.data.started_routes);
                }
                navigate(`/moje-trasy/${id}`);
            } catch (error) {
                console.log(error);
                const { status } = error.response;

                if (status === 401 || status === 403 || status === 405) {
                    dispatch(logOut());
                    navigate("/app/logowanie");
                }
            }
        } else {
            setUrlRegisterModal({ url: `/moje-trasy/${id}` });
            showRegisterModal();
        }
    };

    const createMapElements = () => {
        markers =
            typeof window !== "undefined"
                ? route?.places
                    .filter((place) => place.type !== 4)
                    .map((place, index, self) =>
                        index !== 0 &&
                            self[0].lat !== place.lat &&
                            self[0].lon !== place.lon ? (
                            <TaskMarker
                                key={index}
                                position={[place.lon, place.lat]}
                                icon={LUtils.getTaskMarkerIcon(place)}
                            />
                        ) : (
                            <PlaceMarker
                                key={index}
                                position={[place.lon, place.lat]}
                                icon={LUtils.getMarkerIcon(route)}
                            />
                        )
                    )
                : "";

        paths =
            typeof window !== "undefined" && route ? (
                <Polyline
                    positions={[...route?.places, route?.places[0]].map(
                        ({ lat, lon }) => [lon, lat]
                    )}
                    pathOptions={{ color: LUtils.getColor(route) }}
                />
            ) : (
                ""
            );

        center =
            typeof window !== "undefined"
                ? route?.places
                    ?.map(({ lon, lat }) => [lon, lat])
                    ?.reduce(([latA, lonA], [latB, lonB]) => [
                        (latA + latB) / 2,
                        (lonA + lonB) / 2,
                    ]) ?? [0, 0]
                : "";
    };

    createMapElements();

    const onShare = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: route?.name,
                    url: `https://rynarzewozamosc.pl/trasy/${route?.id}`,
                    text: route?.description,
                })
                .catch(console.error);
        }
    };

    const displayTime = (timeMinutes) => {
        let output = "";

        if (timeMinutes < 60) {
            output = `${timeMinutes}min`;
        } else {
            const hours = Math.floor(timeMinutes / 60);
            const minutes = timeMinutes % 60;

            output = `${hours}h ${minutes !== 0 ? minutes + "min" : ""}`;
        }

        return output;
    };

    return (
        <Layout>
            <SEO title="Home" />

            <PageHeader
                bgImage={{
                    src: process.env.STRAPI_API_URL + route?.thumb?.url,
                    alt: route?.name,
                }}
                backUrl="/trasy"
                AdditionalIcon={() => (
                    <div className="page-header--likes">
                        <Button
                            className="btn--circle page-header--action-btn"
                            onClick={onFavoriteClick(route?.id)}
                            aria-label={`Licznik polubień: ${route?.favoritescount}`}
                        >
                            <Icon
                                className={
                                    isRouteFavorited(route?.id)
                                        ? "icon-heart-filled-min favorited"
                                        : "icon-heart-min"
                                }
                            />
                        </Button>
                        <PlainText className="btn--label">
                            {route?.favoritescount}
                        </PlainText>
                    </div>
                )}
            >
                <Tag
                    className={`tag tag--${difficultyToClassName(
                        route?.difficulty.name
                    )}`}
                >
                    {route?.difficulty.name}
                </Tag>
            </PageHeader>
            <Container className="single-route">
                <Row className="d-flex justify-content-center">
                    <Col lg={10}>
                        <Heading className="single-route--heading" as="h2">
                            {route?.name}
                        </Heading>
                        <div className="route-card--tags pr-2">
                            <div className="icon--with-label mt-3">
                                <Icon className="icon-pin-min" />
                                <PlainText className="icon--label">
                                    Start:{" "}
                                    {route?.locations?.[0]?.name ??
                                        route?.location}
                                </PlainText>
                            </div>
                            <div className="icon--with-label mt-3">
                                <Icon className="icon-distance-min" />
                                <PlainText className="icon--label">{`${(
                                    route?.distance / 1000
                                ).toFixed(1)} km`}</PlainText>
                            </div>
                            <div className="icon--with-label mt-3">
                                <Icon className="icon-bulb-min" />
                                <PlainText className="icon--label">
                                    {
                                        route?.places?.filter(
                                            (place) => place.type !== 4
                                        ).length
                                    }{" "}
                                    wyzwań
                                </PlainText>
                            </div>
                        </div>
                        <div className="route-card--tags route-card--tags__times">
                            <div className="btn--circle__container route-card--tag pr-2">
                                <Button
                                    withoutaction="true"
                                    className="btn--circle btn btn-default"
                                    style={{ marginBottom: "0.8rem" }}
                                >
                                    <span className="sr-only">{`Czas przejścia trasy: ${displayTime(
                                        route?.time_walk
                                    )}`}</span>
                                    <Icon className="icon-walk-min" />
                                </Button>
                                <PlainText className="btn--label text-center">
                                    <img src={clockSvg} alt="" />
                                    &nbsp;
                                    {displayTime(route?.time_walk)}
                                </PlainText>
                            </div>
                            <div className="btn--circle__container route-card--tag pr-2">
                                <Button
                                    withoutaction="true"
                                    className="btn--circle btn btn-default"
                                    style={{ marginBottom: "0.8rem" }}
                                >
                                    <span className="sr-only">{`Czas przejazdu trasy rowerem: ${displayTime(
                                        route?.time_bike
                                    )} min`}</span>
                                    <Icon className="icon-bicycle-min" />
                                </Button>
                                <PlainText className="btn--label text-center">
                                    <img src={clockSvg} alt="" />
                                    &nbsp;
                                    {displayTime(route?.time_bike)}
                                </PlainText>
                            </div>
                            <div className="btn--circle__container route-card--tag pr-2">
                                <Button
                                    withoutaction="true"
                                    className="btn--circle btn btn-default"
                                    style={{
                                        marginBottom: "0.8rem",
                                        maxWidth: "4rem",
                                    }}
                                >
                                    <span className="sr-only">{`Czas przebiegu trasy: ${displayTime(
                                        route?.time_run
                                    )} min`}</span>
                                    <img
                                        src={RunIcon}
                                        alt="run-icon"
                                        style={{ width: 28.82, height: 24 }}
                                    />
                                </Button>
                                <PlainText className="btn--label text-center">
                                    <img src={clockSvg} alt="" />
                                    &nbsp;
                                    {displayTime(route?.time_run)}
                                </PlainText>
                            </div>
                            <div className="btn--circle__container route-card--tag">
                                <Button
                                    withoutaction="true"
                                    className="btn--circle btn btn-default"
                                    style={{ marginBottom: "0.8rem" }}
                                >
                                    <span className="sr-only">{`Czas przejścia trasy nordic walking: ${displayTime(
                                        route?.time_nordic
                                    )} min`}</span>
                                    <Icon className="icon-nordic-walking-min" />
                                </Button>
                                <PlainText className="btn--label text-center">
                                    <img src={clockSvg} alt="" />
                                    &nbsp;
                                    {displayTime(route?.time_nordic)}
                                </PlainText>
                            </div>
                        </div>
                        <div className="route-card--tags route-card--tags__details">
                            {/* <div className="btn--circle__container route-card--tag">
                                <Button
                                    withoutaction="true"
                                    className="btn--circle btn btn-default"
                                    style={{ marginBottom: "0.8rem" }}
                                >
                                    <span className="sr-only">{`Distans trasy: ${(
                                        route?.distance / 1000
                                    ).toFixed(1)} km`}</span>
                                    <Icon className="icon-distance-min" />
                                </Button>
                                <PlainText className="btn--label text-center">
                                    {`${(route?.distance / 1000).toFixed(
                                        1
                                    )} km`}
                                </PlainText>
                            </div> */}
                            <div className="btn--circle__container route-card--tag">
                                <Button
                                    withoutaction="true"
                                    className="btn--circle btn btn-default"
                                    style={{ marginBottom: "0.8rem" }}
                                >
                                    <span className="sr-only">{`Ilość wyzwań na trasie: ${route?.places.filter(
                                        (_) => _.type === 2
                                    ).length
                                        }`}</span>
                                    <Icon className="icon-bulb-min" />
                                </Button>
                                <PlainText className="btn--label text-center">
                                    {
                                        route?.places.filter(
                                            (_) => _.type === 2
                                        ).length
                                    }{" "}
                                    wyzwania
                                </PlainText>
                            </div>
                            <div className="btn--circle__container route-card--tag">
                                <Button
                                    withoutaction="true"
                                    className="btn--circle btn btn-default"
                                    style={{ marginBottom: "0.8rem" }}
                                >
                                    <span className="sr-only">{`Ilość historii na trasie: ${route?.places.filter(
                                        (_) => _.type === 1
                                    ).length
                                        }`}</span>
                                    <Icon className="icon-storytelling-min" />
                                </Button>
                                <PlainText className="btn--label text-center">
                                    {
                                        route?.places.filter(
                                            (_) => _.type === 1
                                        ).length
                                    }{" "}
                                    historii
                                </PlainText>
                            </div>
                            <div className="btn--circle__container route-card--tag">
                                <Button
                                    withoutaction="true"
                                    className="btn--circle btn btn-default"
                                    style={{ marginBottom: "0.8rem" }}
                                >
                                    <span className="sr-only">{`Ilość ćwiczeń na trasie: ${route?.places.filter(
                                        (_) => _.type === 3
                                    ).length
                                        }`}</span>
                                    <Icon className="icon-heart-rate-min" />
                                </Button>
                                <PlainText className="btn--label text-center">
                                    {
                                        route?.places.filter(
                                            (_) => _.type === 3
                                        ).length
                                    }{" "}
                                    ćwiczenia
                                </PlainText>
                            </div>
                        </div>
                        <div className="single-route--map-container">
                            <Button
                                className="btn--circle page-header--action-btn"
                                onClick={onShare}
                            >
                                <Icon className="icon-share-min"></Icon>
                            </Button>
                            <Button
                                className="btn--circle page-header--action-btn btn--help-me btn--help-me__single-route"
                                onClick={() => {
                                    setTutorialModal(true);
                                }}
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24">
                                    <g transform="translate(-104.271 -165.924)">
                                        <path d="M116.271,165.924a12,12,0,1,0,12,12A12,12,0,0,0,116.271,165.924Zm0,22a10,10,0,1,1,10-10A10,10,0,0,1,116.271,187.924Z" />
                                        <path d="M117.131,171.022a4,4,0,0,0-4.64,2.62.985.985,0,0,0,.612,1.251l.028.009a1.014,1.014,0,0,0,1.26-.66,2.028,2.028,0,0,1,2.28-1.28,2,2,0,0,1,1.4,2.2,1.962,1.962,0,0,1-2.08,1.54,1,1,0,0,0-1.113.873.894.894,0,0,0-.007.127l-.016,3.12a.99.99,0,0,0,.979,1h.021a.991.991,0,0,0,1-.98v-.02l.02-2.18a3.89,3.89,0,0,0,.256-7.62Z" />
                                        <path d="M115.891,182.7h-.02a1.029,1.029,0,0,0-1,1v.22a.944.944,0,0,0,.969.92h.031a1.068,1.068,0,0,0,1-1.04v-.16A.96.96,0,0,0,115.891,182.7Z" />
                                    </g>
                                </svg>
                            </Button>
                            {route && (
                                <MapContainer
                                    center={center}
                                    zoom={10}
                                    doubleClickZoom={false}
                                    closePopupOnClick={false}
                                    dragging={false}
                                    zoomSnap={false}
                                    zoomDelta={false}
                                    trackResize={false}
                                    touchZoom={false}
                                    scrollWheelZoom={false}
                                    className="single-route--map"
                                    whenCreated={(mapInstance) => {
                                        mapRef.current = mapInstance;

                                        const locations = route?.places.map(
                                            (a) => {
                                                return [a.lon, a.lat];
                                            }
                                        );

                                        mapInstance.fitBounds(locations, {
                                            padding: [70, 70],
                                        });

                                        mapInstance.on(
                                            "locationfound",
                                            async (e) => {
                                                const currentLocation =
                                                    e.latlng;
                                                const firstPlacePosition = {
                                                    lat: route?.places[0].lon,
                                                    lng: route?.places[0].lat,
                                                };

                                                const distance =
                                                    mapRef.current.distance(
                                                        currentLocation,
                                                        firstPlacePosition
                                                    );

                                                if (
                                                    distance >
                                                    MAX_DISTANCE_TO_START
                                                ) {
                                                    setDistanceModal(true);
                                                } else {
                                                    onStartRoute(
                                                        route?.id,
                                                        true
                                                    );
                                                }
                                            }
                                        );
                                    }}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                                        url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=1a10a4bf-8b40-4f57-8c93-0129034a2537"
                                    />
                                    {markers}
                                    {paths}
                                </MapContainer>
                            )}
                        </div>
                        <div className="single-route--content">
                            <Heading as="h3">Opis trasy</Heading>
                            <Paragraph>{route?.description}</Paragraph>
                        </div>
                        <TaskSlider
                            title="Przykładowe wyzwania na trasie"
                            desc="Po dotarciu do wyznaczonego miejsca na trasie odnacz je w aplikacji i podejmij wyzwanie. Powodzenia!"
                            icon="icon-monocle-min"
                            data={route?.places}
                        />
                        <RoutesSlider
                            sliderRef={sliderRef}
                            centerMode={false}
                            variableWidth={true}
                            withHeading={true}
                            routeId={route?.id}
                            isRouteView={true}
                        />
                    </Col>
                </Row>
                <div className="single-route--start-button__container">
                    <Button
                        className="btn--primary single-route--start-button"
                        onClick={onStartRoute(route?.id, true)}
                    >
                        Wyruszam w trasę
                    </Button>
                </div>
            </Container>

            <Modal
                className="modal--xs"
                modal={distanceModal}
                setModal={() => setDistanceModal(false)}
                BottomActions={() => (
                    <Button
                        className={`btn--primary btn--md btn-block`}
                        onClick={onStartRoute(route?.id, true)}
                    >
                        Kontynuuj mimo to
                    </Button>
                )}
            >
                <Heading as="h1">Błędna lokalizacja</Heading>
                <Paragraph>
                    Jesteś zbyt daleko od trasy, aby rozpocząć jej
                    przechodzenie.
                </Paragraph>
            </Modal>

            <RouteTutorialModal
                show={tutorialModal}
                setShow={setTutorialModal}
            />
        </Layout>
    );
};

export default SingleRoute;
